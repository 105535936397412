@font-face {
  font-family: 'SantanderTextRegular';
  src: url('./assets/fonts/SantanderText/SantanderText-Regular.eot');
  src: url('./assets/fonts/SantanderText/SantanderText-Regular.eot?#iefix') format('embedded-opentype');
  src: url('./assets/fonts/SantanderText/SantanderText-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'SantanderTextBold';
  src: url('./assets/fonts/SantanderText/SantanderText-Bold.eot');
  src: url('./assets/fonts/SantanderText/SantanderText-Bold.eot?#iefix') format('embedded-opentype');
  src: url('./assets/fonts/SantanderText/SantanderText-Bold.otf') format('opentype');
}


@font-face {
  font-family: 'SantanderTextLight';
  src: url('./assets/fonts/SantanderText/SantanderText-Light.eot');
  src: url('./assets/fonts/SantanderText/SantanderText-Light.eot?#iefix') format('embedded-opentype');
  src: url('./assets/fonts/SantanderText/SantanderText-Light.otf') format('opentype');
}
