h3 {
  font-family: $font-light;
  font-size: $text-big-m;
  color: $text;
  letter-spacing: 0;
  line-height: 39px;
  margin-bottom: 20px;

  @media #{$tablet} {
    font-size: $text-big-l;
    line-height: 42px;
  }

  @media #{$desktop} {
    font-size: $text-big-xl;
    line-height: 52px;
  }
}

p {
  font-family: $font-light;
  font-size: $text-xl;
  color: $text;
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: $base;
}
