.grid {
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  padding: 0;

  &.outer-content {
    overflow: visible;
  }

  .row {

    .span-l-6 {
      min-height: 1px;
    }

    .span-m-8 {
      min-height: 1px;
    }

    .span-m-centered {
      min-height: 1px;
    }

    .span-s-12 {
      min-height: 1px;
    }
  }

  // de 0 a 767px
  @media #{$mobile} {
    padding-left: calc(#{$gridMarginMobile} - #{$gridGutter/2});
    padding-right: calc(#{$gridMarginMobile} - #{$gridGutter/2});

    .row {
      width: calc(100%);
      margin-right: #{-$gridGutter};

      .span-l-6 {
        float: left;
        margin-left: #{$gridGutter/2};
        margin-right: #{$gridGutter/2};

      }

      .span-m-8 {
        float: left;
        margin-left: #{$gridGutter/2};
        margin-right: #{$gridGutter/2};
      }

      .span-m-centered {
        float: left;
        margin-left: #{$gridGutter/2};
        margin-right: #{$gridGutter/2};
      }

      .span-s-12 {
        float: left;
        margin-left: #{$gridGutter/2};
        margin-right: #{$gridGutter/2};
      }

      @for $i from 1 through 12 {
        $width: percentage(.08333333 * $i);

        .span-#{$i} {
          width: calc(#{$width} - #{$gridGutter});
        }

        .span-s-#{$i} {
          //   Quitamos el !important y mantenemos el ancho del parrafo
          // tiene el mismo tamaño que la propiedad anterior, por tanto en caso de no superar
          // su especificidad no veríamos cambios en el diseño porque son el mismo
          width: calc(#{$width} - #{$gridGutter});
        }
      }
    }
  }

  //de 768px a 1024
  @media #{$tablet} {
    padding-left: calc(#{$gridMarginTablet} - #{$gridGutter/2});
    padding-right: calc(#{$gridMarginTablet} - #{$gridGutter/2});

    .row {
      width: calc(100%);
      margin-right: #{-$gridGutter};

      .span-l-6 {
        float: left;
        margin-left: #{$gridGutter/2};
        margin-right: #{$gridGutter/2};
      }

      .span-m-8 {
        float: left;
        margin-left: #{$gridGutter/2};
        margin-right: #{$gridGutter/2};
      }

      .span-m-centered {
        float: left;
        margin-left: #{$gridGutter/2};
        margin-right: #{$gridGutter/2};
      }

      .span-s-12 {
        float: left;
        margin-left: #{$gridGutter/2};
        margin-right: #{$gridGutter/2};
      }

      @for $i from 1 through 12 {
        $width: percentage(.08333333 * $i);

        .span-#{$i} {
          width: calc(#{$width} - #{$gridGutter});
        }

        .span-m-#{$i} {
          width: calc(#{$width} - #{$gridGutter});
        }
      }
    }

    .span-m-centered {
      float: none !important;
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }

  // de +1024
  @media #{$desktop} {
    padding-left: calc(#{$gridMarginDesktop} - #{$gridGutter/2});
    padding-right: calc(#{$gridMarginDesktop} - #{$gridGutter/2});

    .row {
      width: calc(100%);
      margin-right: #{-$gridGutter};

      .span-l-6 {
        float: left;
        margin-left: #{$gridGutter/2};
        margin-right: #{$gridGutter/2};

      }

      .span-m-8 {
        float: left;
        margin-left: #{$gridGutter/2};
        margin-right: #{$gridGutter/2};
      }

      .span-m-centered {
        float: left;
        margin-left: #{$gridGutter/2};
        margin-right: #{$gridGutter/2};
      }

      .span-s-12 {
        float: left;
        margin-left: #{$gridGutter/2};
        margin-right: #{$gridGutter/2};

      }

      @for $i from 1 through 12 {
        $width: percentage(.08333333 * $i);

        .span-#{$i} {
          width: calc(#{$width} - #{$gridGutter});
        }

        .span-l-#{$i} {
          width: calc(#{$width} - #{$gridGutter});
        }
      }
    }
  }
}


button#btn-search-offers,
button#btn-search-oportunity,
.search-term {
  cursor: pointer;
  outline: none;
}
