.site {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

@media #{$tablet},
#{$mobile} {
  .cover-bg {
    background-color: rgba(0, 0, 0, .8);
    position: fixed;
    top: 55px;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    transition: .5s;


    &.hide {
      display: none;
    }
  }

}

.fixed {
  position: fixed;
}


// Remove the margin in all browsers (opinionated).
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// sobreescribir material
.mat-tab-header {
  border: 1px solid rgba(0, 0, 0, .12);
}

.cards-tabs .mat-tab-label {
  border: 0 !important;
  border-left: 1px solid rgba(0, 0, 0, .12) !important;
}


//sobreescribir material de faqs
.mat-expansion-panel .mat-expansion-panel-header {
  height: auto !important;
}

.carousel-arrows {
  .slick-list {
    align-items: stretch;
    align-content: stretch;
    display: flex;

    .slick-track {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      align-content: stretch;
      height: 100%;
    }
  }
}